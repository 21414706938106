import React, { Component } from "react";
import { Link, graphql } from "gatsby";
import styled from "styled-components";
import { colors, breakpoints, fonts } from "../style-utilities/variables";
import ReactHtmlParser from "react-html-parser";

//SECTIONS
import ContactBanner from "../components/ContactBanner";
import TabSlider from "../components/TabSliders/DefaultSlider/DefaultSlider";
import Hero from "../components/Hero";
import RelatedContentTechnology from "../components/Technology/RelatedContentTechnology";
import SEO from "../components/SEO";
import DefaultSharingImage from "./../static/ansira-logo-sharing.png";
import ButtonRed from "../components/ButtonRed";
import DefaultSliderSolutionsDynamic from "../components/TabSliders/DefaultSlider/DefaultSliderSolutionsDynamic";

const StyledTechnology = styled.main`
  margin: 0.75em auto;
  margin-top: 0;
  overflow-x: hidden;
  .page-container {
    max-width: 1500px;
    margin: 0 auto;
  }
  .hero-intro p {
    margin-left: 1.5em;
  }
  .center {
    text-align: center;
    margin-top: 2rem;
    margin-bottom: 3rem;
  }
  strong {
    color: black !important;
  }

  @media (min-width: ${breakpoints.tablet}) {
    .center {
      margin-bottom: 6rem;
    }
  }
`;

class Technology extends Component {
  state = {
    isPreviewMode: false,
    previewPayload: null
  };

  componentDidMount() {
    let search = this.props.location.search;
    let params = new URLSearchParams(search);
    /*
    Preview Mode -------------------------------------
    */
    if (params.get("preview")) {
      let id = params.get("id");
      let wpnonce = params.get("_wpnonce");
      let BASE_URL = `https://api.dev.ansira.io/wp-json/wp/v2`;

      fetch(`${BASE_URL}/pages/${id}/revisions/?_wpnonce=${wpnonce}`, {
        mode: "cors",
        credentials: "include"
      })
        .then(response => {
          return response.json();
        })
        .then(myJson => {
          if (myJson) {
            this.setState({
              isPreviewMode: true,
              previewPayload: myJson[0]
            });
          }
        });
    }
    /*
    -------------------------------------------------
    */
  }

  render() {
    let page = this.props.data.wordpressPage;

    if (this.state.isPreviewMode) {
      page.acf = this.state.previewPayload.acf;
    }
    return (
      <StyledTechnology className="animate">
        <SEO
          isBlogPost={false}
          postData={page}
          postImage={DefaultSharingImage}
        />
        <Hero
          headline={page.acf.hero_headline}
          sub={page.acf.hero_sub_headline}
        />
        <div className="page-container">
          <p className="intro-copy">
            {ReactHtmlParser(page.acf.intro_copy)}
          </p>
        </div>
        <div className="page-container">
          <div className="center">
            <img
              className="section-image"
              src={`${page.acf.section_image.source_url}`}
            />
            {page.acf.section_copy ? (
              <p className="copy">{ReactHtmlParser(page.acf.section_copy)}</p>
            ) : (
              <></>
            )}
            {page.acf.section_cta ? (
              <ButtonRed
                textLabel={page.acf.section_cta}
                pageLink={page.acf.section_url}
              />
            ) : (
              <></>
            )}
          </div>
        </div>
        {page.acf.create_slider.map((item, i) => {
           return ( <DefaultSliderSolutionsDynamic
             data={item.default_slider}
             headline={item.slider_headline}
             type={page.acf.slider_type}
             index={i}
             bgcolor="black"
           />
         )
        })}
        <div className="page-container">
          <RelatedContentTechnology />
          <ContactBanner
            headline={page.acf.contact_headline}
            cta={page.acf.contact_cta}
            url={page.acf.contact_url}
          />
        </div>
      </StyledTechnology>
    );
  }
}

export const query = graphql`
  query {
    wordpressPage(slug: { eq: "technology" }) {
      content
      slug
      title
      type
      acf {
        seo_title
        seo_canonical
        seo_description
        hero_headline
        hero_sub_headline
        intro_copy
        section_image {
          source_url
        }
        section_copy
        section_cta
        section_cta_url
        slider_type
        create_slider {
          slider_headline
          default_slider {
            slider_label
            slider_copy
            slider_cta_text
            slider_cta_url
          }
        }
        contact_headline
        contact_cta
        contact_url
      }
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
        yoast_wpseo_canonical
      }
    }
  }
`;

export default Technology;
